import { createGlobalStyle } from "styled-components"

import regular from "assets/fonts/gilroy-regular.otf"
import regularWoff from "assets/fonts/gilroy-regular-webfont.woff"
import regularWoffTwo from "assets/fonts/gilroy-regular-webfont.woff2"
import semibold from "assets/fonts/gilroy-semibold.otf"
import semiboldWoff from "assets/fonts/gilroy-semibold-webfont.woff"
import semiboldWoffTwo from "assets/fonts/gilroy-semibold-webfont.woff2"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: ${({ theme }) => theme.font.family.sans};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    font-style: normal;
    src: url(${regularWoffTwo}) format('woff2'),
      url(${regularWoff}) format('woff'),
      url(${regular}) format('opentype');
  }

  @font-face {
    font-family: ${({ theme }) => theme.font.family.sans};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-style: normal;
    src: url(${semibold}) format('opentype'),
      url(${semiboldWoff}) format('woff'),
      url(${semiboldWoffTwo}) format('woff2');
  }
  
  *, *::after, *::before {
    box-sizing: border-box;
  }

  html,
  body {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    max-height: 100vh;
    font-family: ${({ theme }) => theme.font.family.sans}, sans-serif;
    font-size: ${({ theme }) => theme.font.size.standard};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.greenExtraLight};
  }
`

export default GlobalStyles
