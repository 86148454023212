import React from "react"
import styled from "styled-components"

import GlobalStyles from "styles/GlobalStyles"
import Header from "components/Header"

import backgroundTop from "assets/images/brand-top.svg"
import backgroundBottom from "assets/images/brand-bottom.svg"

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (code: string) => void
    }
    URL_PARAMS: unknown
    _env_?: {
      API_URL: string
      AZURE_STORAGE_PARAM: string
    }
  }
}

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <Container>
      <GlobalStyles />
      <Header />
      <LayoutContent>{children}</LayoutContent>
    </Container>
  )
}

const Container = styled.div`
  background-image: url(${backgroundTop}), url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  position: relative;
  max-width: 100%;
  min-height: 100vh;
`

const LayoutContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  max-width: ${({ theme }) =>
    `calc(${theme.media.lg} + 2 * ${theme.spacer.medium})`};
`

export default Layout
