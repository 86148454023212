// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-teams-activeren-tsx": () => import("./../../../src/pages/teams/activeren.tsx" /* webpackChunkName: "component---src-pages-teams-activeren-tsx" */),
  "component---src-pages-teams-qr-code-index-tsx": () => import("./../../../src/pages/teams/qr-code/index.tsx" /* webpackChunkName: "component---src-pages-teams-qr-code-index-tsx" */),
  "component---src-pages-verificatie-index-tsx": () => import("./../../../src/pages/verificatie/index.tsx" /* webpackChunkName: "component---src-pages-verificatie-index-tsx" */)
}

