const theme = {
  color: {
    black: "#1d1d1d",
    blackSuperAlpha: "rgba(29, 29, 29, 0.1)",
    white: "#ffffff",
    pinkDark: "#e8038b",
    pink: "#ff0098",
    pinkLight: "#ff99d6",
    pinkSuperLight: "#ffebf7",
    blue: "#0055ff",
    blueMediumLight: "#99bbff",
    blueLight: "#d6e4ff",
    blueSuperLight: "#ebf2ff",
    blueAlpha: "rgba(214, 228, 255, 0.5)",
    greenSuperDark: "#007474",
    greenExtraDark: "#008888",
    greenLightDark: "#009b9b",
    greenDark: "#00c9a5",
    green: "#00dfb8",
    greenLight: "#d6faf3",
    greenSuperLight: "#E5F8F6",
    greenExtraLight: "#f6fafa",
    yellowDark: "#e6c800",
    yellow: "#fde110",
    yellowLight: "#FCF9E5",
    greyDark: "#666666",
    grey: "#919191",
    greyLight: "#ececec",
    greySuperLight: "#f9f9f9",
    greyLightBlue: "#f6f9ff",
    error: "#ff2a50",
  },
  spacer: {
    tiny: "4px",
    small: "8px",
    standard: "16px",
    medium: "24px",
    big: "32px",
    ample: "40px",
    large: "48px",
    massive: "56px",
    huge: "64px",
  },
  dims: {
    tiny: "16px",
    small: "24px",
    standard: "32px",
    medium: "40px",
    big: "48px",
    ample: "56px",
    large: "64px",
    massive: "72px",
    huge: "88px",
  },
  font: {
    size: {
      superTiny: "12px",
      tiny: "14px",
      small: "16px",
      standard: "18px",
      medium: "24px",
      big: "32px",
      huge: "40px",
    },
    family: {
      sans: "Gilroy",
      serif: "serif",
    },
    weight: {
      normal: "normal",
      bold: 600,
    },
  },
  media: {
    xs: "540px",
    sm: "720px",
    md: "960px",
    lg: "1160px",
  },
  border: {
    radius: {
      tiny: "4px",
      small: "8px",
      standard: "12px",
      big: "24px",
      large: "32px",
    },
  },
}

type Theme = typeof theme

export type ThemeColor = keyof Theme["color"]
export type ThemeSpacer = keyof Omit<Theme["spacer"], "times"> | number
export type ThemeMedia = keyof Theme["media"]
export type ThemeFontSize = keyof Theme["font"]["size"]
export type ThemeFontFamily = keyof Theme["font"]["family"]
export type ThemeFontWeight = keyof Theme["font"]["weight"]
export type ThemeBorderRadius = keyof Theme["border"]["radius"]

export default theme
