import * as React from "react"
import styled from "styled-components"

import frieslandLogo from "assets/icons/logo.svg"

const Header = () => {
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <img src={frieslandLogo} alt="" />
      </LogoWrapper>
    </HeaderWrapper>
  )
}

const LogoWrapper = styled.div`
  width: 156px;
  padding: ${({ theme }) => [theme.spacer.ample, " ", theme.spacer.huge]};

  @media (max-width: 1024px) {
    padding: 25px auto 0px auto;
  }
`

const HeaderWrapper = styled.header``

export default Header
