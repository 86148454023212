const React = require("react")
const { ThemeProvider } = require("styled-components")
const { QueryClientProvider, QueryClient } = require("react-query")

const theme = require("styles/theme")
const { Layout } = require("components")

const queryClient = new QueryClient()

exports.wrapRootElement = ({ element }) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme.default}>
          <>{element}</>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
